@media screen and (max-width: 400px) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }

  #about .about-text {
    padding-top: 10px;
  }
}
@media (max-width: 1198px) {
  img#app-header {
    display: none;
  }
}
